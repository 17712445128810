import { supabase } from "../supabase/supabase";
import { ReadItem } from "../compensations/types";


// TODO: move this to a Deno serverless function
export async function readPdfTicket(pdf: File): Promise<ReadItem> {

  const { data, error } = await supabase.functions.invoke('create-compensation', {
    body: {pdf}
  })

  console.log({ data, error })

  return data
}
