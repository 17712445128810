import { ReadItem } from "../compensations/types";
import { supabase } from "../supabase/supabase";
import { fileToBase64 } from "../../utils/base64";


// TODO: move this to a Deno serverless function
export async function readImageTicket(img: File): Promise<ReadItem> {

  const base64Img = await fileToBase64(img);

  const { data, error } = await supabase.functions.invoke('create-compensation', {
    body: {base64Img}
  })

  console.log({ data, error })

  return data
}
